.card {
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.rotate-card {
    transform: rotateX(0deg);
}

.card-body {
    backface-visibility: hidden;
}

.card-body form {
    display: flex;
    flex-direction: column;
}

.card-body form .form-control {
    margin-bottom: 10px;
}